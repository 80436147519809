@import "../../utils/_variables";

.make-visible {
  @include textShadow();
}

.BigContainer {
  h1 {
    @include vw-pixels((
      'font-size': 38,
    ));
  }
  display: flex;
  flex-direction: column;
  margin: 130px 0px;
  padding: 0px 5%;
  position: relative;
  width: 100%;

  @media #{$mobileAndTabletOnly} {
    margin: 130px 0;
  }
}

.QuestContainer {
  display: flex;
  flex-direction: column;
  margin: 0 400px 0 0;

  iframe {
    margin: 30px 0 0 0;
  }

  .DeliverablesContainer {
    max-width: 75vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1% 0 0 0;
    width: 100%;
    @include textShadow();
  
    .deliverablesList {
      margin: 35px 0 0 0;
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      @include vw-pixels((
        'font-size': 25,
      ));
      @media #{$mobileOnly} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      @include vw-pixels((
        'font-size': 25,
      ));
      }

      .deliverable {
        flex-basis: 50%;
        width: 100%;

        @media #{$mobileOnly} {
          width: 60%;        
        }
     
        min-width: 50%;
        margin: 0 0 32px 0;
        color: white;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: baseline;

        .iconBg {
          min-width: 64px;
          min-height: 64px;
          @include vw-pixels((
            'width': 84,
            'height': 84,
            'border-radius': 50,
          ));
          background-repeat: no-repeat;
          margin: 0 21px 0 0;
          display: flex;
          background: black;
          align-items: center;
          justify-content: center;
        }
      }

      img.icon {
        margin: auto;
        width: 50%;
      }
    }
  }

  @media #{$mobileAndTabletOnly} {
    margin: 0;

    .DeliverablesContainer {
      .deliverablesList {
        flex-wrap: nowrap;
        flex-direction: column;

        span.deliverable {
          width: 100%;
        }
      }
    }
  }

  .GameSection {
    h1 {
      @include vw-pixels((
        'font-size': 30,
      ));
    }

    @media #{$tabletAndLarger} {
      max-width: 65vw;
    }

    margin: 100px 0 67px 0;
    display: flex;
    flex-direction: row;
    padding: 0 56px 0 56px;

    .gameDesc {
      @include textShadow();
      h1 {
        margin: 0 0 18px 0;
      }
    }

    .gameImage {
      display: flex;
      flex-direction: column;
      margin: 0 64px 0 0;
      img {
        max-width: 150px;
      }
    }

    img {
      margin: 0 0 20px 0;
      min-width: 216px;
    }

    @media #{$mobileOnly} {
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      margin: 67px 0;

      .gameImage {
        margin: 0 0 64px 0;
        width: 216px;
      }
    }
  }

  .GameDescription {
    #text {
      @include vw-pixels((
        'font-size': 15,
      ));
    }

    .subtitle-text {
      @include vw-pixels((
        'font-size': 15,
      ));
    }

    display: flex;
    flex-direction: row;
    padding: 0 0 0 56px;

    .gameItem {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 30%;

      .subtitle {
        color: $colorGray;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 0 0 15px 0;
        text-transform: uppercase;

        @include vw-pixels((
          'font-size': 14,
        ));
      }
    }

    @media #{$mobileOnly} {
      flex-direction: column;
      padding: 0 0 0 20px;

      .gameItem {
        font-size: 12px;
        margin: 0 0 30px 0;
        width: 100%;
      }
    }
  }

  .GameGallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5% 0%;

    @media #{$tabletAndLarger} {
      max-width: 65vw;
    }

    .galleryItem {
    	cursor: pointer;
      margin: 16px 0;
    	position: relative;
    	width: calc(50% - 16px);

    	span.cornerBtn {
        border-bottom: 48px solid rgba(0, 0, 0, 0.75);
    		border-left: 48px solid transparent;
        bottom: 0;
    		height: 0;
    		opacity: 0;
        position: absolute;
        right: 0;
    	  transition: opacity 0.3s ease;
        width: 0;
    	}

    	&:hover {
    	  box-shadow: 0 0 24px 0 rgba(255, 255, 255, 0.25);
    	  transition: 0.3s ease;

    	  span.cornerBtn {
    	  	display: block;
    	  	opacity: 1;
    	  }
    	}
    }

    @media #{$mobileAndTabletOnly} {
      padding: 0;

      .galleryItem {
        width: 100%;
      }
    }
  }

  .EligibilityContainer {
    align-items: center;
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 35px 0 0 0;
    padding: 40px 10px 65px 10px;
    text-align: center;

    h1 {
      width: 100%;
    }

    border-radius: 10px;
    @include textShadow();

    img {
      -webkit-filter: drop-shadow(3px 3px 3px #222);
      filter: drop-shadow(3px 3px 3px #222);
    }

    @media #{$mobileAndTabletOnly} {
      .eligibilityList {
        flex-wrap: wrap;
        margin: 0 37px;
      }

      .eligibilityItem {
        width: 50%;
        max-width: 50% !important;
      }
    }

    .eligibilityList {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      @include textShadow();
      width: 100%;

      @media #{$mobileOnly} {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .eligibilityItem {
        @media #{$mobileOnly} { 
          margin-top: 20px;
          font-size: 15px;
        }

        display: flex;
        flex-basis: 1;
        flex-direction: column;
        font-weight: 900;

        @include vw-pixels((
          'font-size': 80,
        ));
        
        align-items: center;
        max-width: 25%;

        img {
          width: 73px;
          height: 73px;
          margin: 20px 0 40px 0;
          object-fit: contain;
        }

        span.minACCV {
          margin: 0 0 0 0;
          font-size: 60;
        }

        span.label {
          text-transform: uppercase;
          @include vw-pixels((
            'font-size': 17,
          ));
          font-weight: bold;
          letter-spacing: 1px;
        }
        
        .GamesPlayedModal_button {
          cursor: pointer;
          color: $colorWhite;
          text-transform: uppercase;
          @include vw-pixels((
            'font-size': 14,
          ));
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
    }
  }

  .faqSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 48px 0 48px 0px;

    @media #{$tabletAndLarger} {
      max-width: 65vw;
    }
  
    @media #{$mobileAndTabletOnly} {
      margin: 36px 0 0 0;
    }
  }

  .gameGalleryBar {
    display: flex;
    justify-content: center;
    margin: 32px 0 0 0;

    button {
      width: 216px;
    }
  }
}

.MoreQuestsContainer {
  width: 100%;
  margin: 192px 0 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 30%;

  h1 {
    margin: 0 0 34px 0;
  }
}

.bg-manager {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: .5;  
}