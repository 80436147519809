.small-btn {
  &.forbid span {
    color: #9b9b9b;
    cursor: not-allowed;
  }
  span {
    color: #4ae1ca;
  }
}
.item {
  display: flex;

  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}
.user {
  margin: 29px 15px 32px 15px;
}
.card {
  margin: 0 15px;
}
