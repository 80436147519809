@import "../../../../utils/_variables";

.QuestInsights_Wrapper {
  width: 100%;

  // opacity: 0.3;
  // background-image: linear-gradient(to bottom, #a142ff, #0075e4);

  .QuestInsights {
    align-items: flex-start;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("https://cdn.zeplin.io/61b39e894d20733a8bc58e68/assets/12C9A4E7-2801-4336-98BC-78167A90C629.png");
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 188px;
    justify-content: center;
    object-fit: contain;
    padding: 17px;
    width: 100%;

    .QuestInsights__Header {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      // line-height: 1.25;
      letter-spacing: 0.24px;
      margin-bottom: 20px;
      text-align: left;
    }

    .QuestInsights__Content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;

      .QuestInsights__Content_Search_Bar {
        align-items: center;
        display: flex;
        height: 50px;
        padding: 0 15px;
        width: 100%;

        @include background-blur-and-border;

        svg {
          height: 21px;
          margin-right: 5px;
          width: 20px;
        }

        #quest-insight-search-input {
          background: none;
          border: none;
          box-shadow: none;
          color: $colorWhite;
          margin: none;
          width: 100%;

          &:focus {
            outline: none;
          }
        }
      }

      .QuestInsights__Values {
        background-color: $colorBackground;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        left: 0;
        max-height: 200px;
        overflow: auto;
        position: absolute;
        top: 103%;
        width: 100%;

        .QuestInsights__Values__NoValues {
          @include center-content;
          font-style: italic;
          padding: 3px 5px 3px 5px;
        }

        .QuestInsights__Values__Value {
          background-color: rgba(255, 255, 255, 0.35);
          cursor: pointer;
          padding: 3px 5px 3px 5px;
          width: 100%;
          
          &:nth-child(odd) {
            background-color: rgba(255, 255, 255, 0.25);
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.5);
          }

          &:first-child {
            border-radius: 10px 10px 0 0;
          }

          &:last-child {
            border-radius: 0 0 10px 10px;
          }
        }
      }

      .QuestInsights__Content_Search_Submit {
        @include center-content;
        cursor: pointer;
        height: 50px;
        margin-left: 12px;
        max-width: 50px;
        min-width: 50px;

        @include background-blur-and-border;

        svg {
          height: 20px;
          width: 17px;
        }
      }
    }
  }
}
