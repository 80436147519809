@import "../../../../utils/_variables";

.AddNew_Wrapper {
  margin-bottom: 23px;

  .AddNew {
    @include center-content;
    @include transform-scale(1.01);
    border-radius: 8px;
    cursor: pointer;
    padding: 15px;
    height: 89px;
    width: 100%;

    .AddNew__Details {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-evenly;
      text-align: center;
      width: 100%;

      span {
        @include vw-pixels(('font-size': 24));
        font-stretch: normal;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.3px;
        line-height: 1.2;
        text-align: left;

        @media #{$mobileOnly} {
          @include vw-pixels(('font-size': 15));
        }
      }

      .AddNew__Icon {
        @include center-content;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        flex-shrink: 0;
        margin-left: 5px;
        svg {
          height: auto;
          width: 30px;
        }
      }
    }
  }
}
