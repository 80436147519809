@import "../../../../../utils/_variables";

.DiscoverStreamers {
  display: flex;
  flex-direction: column;

  width: 100%;

  .Offline {
      color: $colorEGray;
    }

  .DiscoverStreamers__Title {
    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    margin-bottom: 22px;
  }

  .DiscoverStreamers__IsLiveFilter {
    @include center-content;
    @include no-select;

    height: 45px;
    min-height: 45px;
    width: 140px;
    padding: 0 28px;

    border: 2px solid $colorDarkPurple;
    border-radius: 8px;
    color: $colorDarkPurple;
    cursor: pointer;

    &.LiveEnabled {
      background-color: $colorDarkPurple;
      color: $colorBlack;
    }
  }

  .DiscoverStreamers__List {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    margin-top: 48px;
    padding: 0;
    width: 100%;

    .DiscoverStreamers__List__Item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 147px;
      width: 100%;

      margin: 10px 0;
      padding: 14px;

      background-color: $colorBackground;
      border-radius: 10px;

      @media #{$mobileAndTabletOnly} {
        flex-direction: column;

        height: auto;
      }

      .DiscoverStreamers__List__Item__Avatar {
        height: 118px;
        width: 118px;

        border-radius: 8px;

        object-fit: cover;
      }

      .DiscoverStreamers__List__Item__Info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        height: 100%;
        width: 100%;

        padding: 10px;

        .DiscoverStreamers__List__Item__Info__Live {
          align-items: center;
          cursor: pointer;
          display: flex;

          svg {
            height: 12px;
            margin-right: 7px;
            width: auto;
          }

          .DiscoverStreamers__List__Item__Info__Live__Live {
            font-size: 13px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.84px;

            margin-right: 7px;
          }
        }

        .DiscoverStreamers__List__Item__Info__Name {
          font-size: 20px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.5px;
          color: $colorSoftPurple;
        }

        .DiscoverStreamers__List__Item__Info__Stats {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          width: 100%;

          @media #{$mobileAndTabletOnly} {
              flex-direction: column;
          
              height: auto;
            }

          .DiscoverStreamers__List__Item__Info__Stats__Stat {
            align-items: center;
            display: flex;
            margin-right: 12px;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;

            svg {
              height: 21px;
              width: auto;

              margin-right: 7px;
            }

            .DiscoverStreamers__List__Item__Info__Stats__Stat__Text {
              font-size: 13px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: 0.28px;

              &.GameNameOnline {
                color: $colorCyan;
              }
            }
          }
        }
      }

      .DiscoverStreamers__List__Item__ProfileButton {
        @include purple-gradient-button;

        height: 48px;
        width: 140px;
        min-width: 120px;

        font-weight: bolder;
      }
    }
  }

  .DiscoverStreamers__LoadMore {
    @include center-content;

    width: 100%;

    margin-top: 10px;

    .DiscoverStreamers__LoadMore__Button {
      @include purple-gradient-button;

      height: 48px;
      min-width: 140px;
      width: 80%;

      font-weight: bolder;
    }
  }
}