@import '../../../../utils/_variables';

.ReviewItems {
  background-color: $colorBackground;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;

  .ReviewItems__Blank {
    @include center-content;
    flex-direction: column;
    height: 100%;
    width: 100%;

    & > * {
      margin: 10px 0;
    }

    svg {
      height: auto;
      width: 66px;
    }

    .ReviewItems__Blank_Text {
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.2px;
      text-align: center;
      width: 70%;
    }

    .ReviewItems__Blank_Button {
      @include purple-gradient-button;
      @include transform-scale;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      height: 48px;
      letter-spacing: normal;
      text-align: center;
      width: 80%;
    }
  }

  .ReviewItems__List {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    list-style: none;
    margin-top: -5px;
    padding: 20px 10px 16px 10px;
    width: 100%;

    a {
      @include center-content;
      height: 100%;
      width: 100%;
    }

    ul {
      margin: none;
      margin-block: none;
    }

    li {
      border-bottom: 1px solid $colorCDarkGray;
      margin-bottom: 20px;
    }

    li:last-child {
      @include white-border-button;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: normal;
      line-height: normal;
      margin: 0;
      text-align: center;
    }

    .ReviewItems__Card {
      align-items: center;
      display: flex;
      flex: 1;
      // height: 115px;
      justify-content: space-between;
      width: 100%;

      .ReviewItems__Card_Img {
        border-radius: 50%;
        height: 45px;
        margin-right: 7px;
        object-fit: cover;
        width: 45px;
      }

      .ReviewItems__Card_Info {
        display: flex;
        flex-basis: 70%;
        flex-direction: column;
        justify-content: center;
        width: 109px;

        .ReviewItems__Card_Info_Name {
          color: $colorSoftPurple;
          cursor: pointer;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          // line-height: 1.36;
          letter-spacing: normal;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            color: $colorCyan;
          }
        }

        .ReviewItems__Card_Info_Title {
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          // line-height: 3;
          letter-spacing: 0.24px;
          text-align: left;
        }

        .ReviewItems__Card_Info_Requirement {
          a {
            color: $colorCyan;
            // text-decoration: none;
            justify-content: left;
          }
        }

        .ReviewItems__Card_Info_Date {
          color: $colorCGray;
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          // line-height: 1.58;
          letter-spacing: normal;
          text-align: left;
        }
      }

      .ReviewItems__Card_Actions {
        @include center-content;

        .ReviewItems__Card_Actions_Undo {
          @include center-content;
          background-color: $colorGray;
          border-radius: 8px;
          cursor: pointer;
          height: 50px;
          position: relative;
          width: 80px;

          .ReviewItems__Card_Actions_Undo_Text {
            // color: $colorCyan;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            z-index: 100;
          }

          .ReviewItems__Card_Actions_Undo_Progress {
            background-color: $colorDarkGray;
            border-radius: 8px 0 0 8px;
            height: 100%;
            left: 0;
            position: absolute;
            // transition: width 1s ease-in-out;
            z-index: 99;
          }
        }

        .ReviewItems__Card_Actions_Review {
          @include purple-gradient-button;
          height: 45px;
          width: 85px;
        }

        .ReviewItems__Card_Actions_Button {
          @include center-content;
          border-radius: 50%;
          cursor: pointer;
          height: 35px;
          margin-left: 8px;
          width: 35px;

          svg {
            height: 22px;
            width: 22px;
          }
        }

        .Accept {
          background-color: $colorCandyGreen;
        }

        .Reject {
          background-color: $colorCandyRed;
        }
      }
    }

    .ReviewItems__List_View_All {
      @include no-select;

      border-radius: 26px;
      height: 48px;

      width: 100%;
    }
  }

  .ActiveItems__View_All {
    @include white-border-button;
    height: 48px;
    margin: 26px 0;

    width: 80%;

    a {
      align-items: center;

      display: flex;

      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      height: 100%;
      justify-content: center;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      width: 100%;
    }
  }
}