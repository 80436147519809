@import  '../../utils/_variables';
.Welcome {
	font-family: Roboto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 100%;
	min-height: 100vh;
	background-image: url("../images/bg.png");
  padding-top: 120px;
  .image-sizer{
		opacity: 0;
		&.image-sizer-gamers{
			width: 140px;
			height: 125px;
		}
		&.image-sizer-streamers{
			width: 140px;
			height: 125px;
		}
	}
	.ModalMaster {
		.Modal {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			overflow-x: hidden;
			background: rgba(0,0,0,.5);
			.Modal__layer {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}
			align-items: flex-start;
			@media screen and (min-width: 640px) {
				align-items: flex-start;
			}
			.Modal__wrapper {
				width: 90%;
				max-width: 1000px;
				height: 600px;
				max-height: 700px;
				background: #202227;
				padding: 0 15px 0 15px;
				position: relative;
				text-align: center;
				align-items: center;
				justify-content: center;
				transform: scale(0.8);
				margin: 5vw 0 5vh;
				max-height:2000px !important;
				padding-bottom:50px !important;
				height:auto !important;
				@media screen and (max-width: 640px) {
					max-height:2000px !important;
					padding-bottom:50px !important;
					height:auto !important;
				}
				@media #{$mobileOnly}{
					padding: 0 10% 0 10%;
				}
				.Modal__title {
					font-size: 25px;
					letter-spacing: 2px;
					margin: 20px 0 0;
				}
				.Modal__close-btn {
					width: 35px;
					height: 35px;
					margin-left: auto;
					position: absolute;
					right: 30px;
					top: 30px;
					cursor: pointer;
					&:before {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						transform-origin: center;
						transform: rotate(45deg);
					}
					&:after {
						content: "";
						width: 3px;
						height: 90%;
						background: #37393D;
						display: block;
						position: absolute;
						left: 50%;
						transform-origin: center;
						transform: rotate(-45deg);
					}
				}
			}
			&.active {
				visibility: visible;
				opacity: 1;
				.Modal__wrapper {
					transform: scale(1);
				}
			}
		}
		.Modal__welcome {
			a, button {
				color: #3a74e0;
				cursor: pointer;
				font-weight: 700;
				text-decoration: none;
				@media screen and (max-width: 1023px) {
					&.desktop{
						display:none !important;
					}
				}
				@media screen and (min-width: 1024px) {
					&.mobile{
						display:none !important;
					}
				}
			}
			.Modal__wrapper {
				max-height: 650px;
				.Welcome__header {
					margin-top: 70px;
					margin-bottom: 30px;
					@media #{$mobileOnly}{
						margin-bottom: 40px;
					}
					h1 {
						font-size: 40px;
						margin-bottom: 5px;
					}
					.Welcome__header__description{
						color: #83858c;
    				font-size: 16px;
					}
				}
				.Welcome__body {
					display: flex;
					@media #{$mobileOnly}{
						display: block;	
					}
					.shapes-container{
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						.shape{
							position: absolute;
							// background: grey;
							width: 10%;
							height: 10%;
						}
						.box{
							position: absolute;
						}
						&.inactive + img{
							transform: translate(0, -40px);
						}
					}
          .Welcome__gamers{
            flex: 1;
						border-right: 1px solid #37393D;
						display: flex;
						flex-direction: column;
						align-items: center;
						@media #{$mobileOnly}{
							border: none;
							margin-bottom: 40px;
						}
          	.Welcome__gamers__image{
							position: relative;
							width: 140px;
							.shapes-container-gamer{
								position: absolute;
								width: 100%;
								height: 100%;
								&.inactive .shape{
									&.shape2{
										transform: translate(50%,50%);
									}
									&.shape3{
										transform: translate(120%,0);
									}
									&.shape4{
										
									}
									&.shape5{
										
									}
									&.shape6{
										transform: translate(-20%,0);
									}
									&.shape7{
										transform: translate(0,100%);
									}
									&.shape8{
										transform: translate(-200%,50%);
									}
								}
								.shape{
									&.shape1{
										width: 100%;
										height: 100%;
										background-image: url('../../images/welcome/icon-controller-white.png');
										background-repeat: no-repeat;
										transition: transform 1100ms ease-out;
										left: 20%;
										top:35%;
										z-index: 10;
									}
									&.shape2{
										left: 0%;
										top: 0%;
										width: 45%;
										height: 50%;
										background: #3a74e0;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape3{
										left: 15%;
										top: 50%;
										width: 22.5%;
										height: 25%;
										background: #fd8d82;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape4{
										left: 28%;
										top: 63%;
										width: 12%;
										height: 12%;
										background: #202227;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape5{
										left: 30%;
										top: 30%;
										width: 20%;
										height: 20%;
										background: #202227;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape6{
										left: 45%;
										top: 30%;
										width: 45%;
										height: 65%;
										background: #0D1116;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape7{
										left: 55%;
										top: 10%;
										width: 10%;
										height: 10%;
										background: #BA40FF;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape8{
										left: 90%;
										top: 20%;
										width: 10%;
										height: 10%;
										background: #0D1116;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
								}
							}
            }
						.Welcome__gamers__title{
							font-weight: 700;
							font-size: 30px;
							margin-bottom: 10px;
						}
						.Welcome__gamers__row{
							font-weight: 300;
							margin: 5px 0;
						}
					}
					.Welcome__streamers{
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						@media #{$mobileOnly}{
							margin-bottom: 40px;
						}
						.Welcome__streamers__image{
							position: relative;
							width: 140px;
							.shapes-container-streamer{
								position: absolute;
								width: 100%;
								height: 100%;
								&.inactive .shape{
									&.shape1{
									}
									&.shape2{
										transform: translate(100%,40%);
									}
									&.shape3{
										transform: translate(0,150%);
									}
									&.shape4{
										transform: translate(100%,0);
									}
									&.shape5{
										transform: translate(0%,0);
									}
									&.shape6{
										transform: translate(0%,0);
									}
									&.shape7{
										transform: translate(0%,-20%) scale(1.3);
									}
									&.shape8{
										transform: translate(-40%,0);
									}
								}
								.shape{
									&.shape1{
										background-image: url('../../images/welcome/icon-camera-white.png');
										width: 100%;
										height: 100%;
										background-repeat: no-repeat;
										transition: transform 1100ms ease-out;
										left: 15%;
										top:32%;
										z-index: 10;
									}
									&.shape2{
										width: 25%;
										height: 30%;
										left: 0;
										top: 20%;
										background: #49e1ca;
										z-index: 4;
										transition: transform 1100ms ease-out;
									}
									&.shape3{
										width: 40%;
										height: 30%;
										left: 15%;
										top: 0;
										z-index: 4;
										background: #49e1ca;
										transition: transform 1100ms ease-out;
									}
									&.shape4{
										width: 30%;
										height: 30%;
										left: 0;
										top: 60%;
										z-index: 3;
										background: #3a74e0;
										transition: transform 1100ms ease-out;
									}
									&.shape5{
										width: 10%;
										height: 12.5%;
										left: 20%;
										top: 77.5%;
										background: #202227;
										z-index: 3;
										transition: transform 1100ms ease-out;
									}
									&.shape6{
										width: 12%;
										height: 14%;
										left: 33%;
										top: 38%;
										background: #fd8d82;
										z-index: 3;
										transition: transform 1100ms ease-out;
									}
									&.shape7{
										width: 40%;
										height: 40%;
										left: 30%;
										top: 60%;
										background: #0D1116;
										z-index: 5;
										transition: transform 1100ms ease-out;
									}
									&.shape8{
										width: 30%;
										height: 37%;
										left: 55%;
										top: 45%;
										background: #0D1116;
										z-index: 5;
										transition: transform 1100ms ease-out;
									}
								}
							}
						}
						.Welcome__streamers__title{
							font-weight: 700;
							font-size: 30px;
							margin-bottom: 10px;
						}
						.Welcome__streamers__row{
							font-weight: 300;
							margin: 5px 0;
						}
					}
				}
				.Welcome__footer {
					margin-top: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom:30px;
					.Welcome__footer__button {
						button, a {
							display: block;
							background-color: #3A74E0;
							color: white;
							cursor: pointer;
							text-decoration: none;
							text-align: center;
							font-weight: normal;
							border-radius: 4px;
							font-weight: 900;
							font-size: 16px;
							padding: 10px 30px;
						}
					}
				}
			}
		}
	}
}
