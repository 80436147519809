@import "../../../../../utils/_variables";

.Gallery {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 35px;

  .Gallery__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .Gallery__Title {
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.24px;
      text-align: left;
    }

    .Gallery__Nav {
      display: flex;

      margin-bottom: 25px;

      .Gallery__Nav__Button {
        @include center-content;

        height: 39px;
        width: 39px;
        margin-left: 15px;

        border-radius: 26px;
        border: solid 2px $colorDGray;
        // background-color: $colorDDarkGray;
        color: $colorDGray;
        cursor: not-allowed;

        svg {
          width: 10px;
          height: auto;
        }
      }

      .Clickable {
        border: none;
        background-color: $colorPurpleRay;
        cursor: pointer;
      }
    }
  }

  .Gallery__Pages {
    display: flex;
    width: 100%;
    min-height: 275px;
    .GalleryPage {
      max-width: 0%;

      .GalleryImage {
        width: 49%;
        height: 244px;

        border-radius: 8px;
        cursor: pointer;
        object-fit: cover;
      }
    }

    .Active {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      width: 100%;
    }
  }
}
