@import "../../../../../utils/_variables";

.QuestDisplay {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .QuestDisplay_Title {
    margin: 20px 0;

    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .QuestDisplay_Pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    // margin-bottom: 20px;

    .QuestDisplay_Pagination_Page_Size {
      display: flex;
      align-items: center;
      flex-direction: row;
      .CustomInput__Dropdown {
        width: 65px;
      }
    }

    .QuestDisplay_Pagination_Buttons {
      display: flex;
      align-items: center;

      .QuestDisplay_Pagionation_Current_Page {
        margin-left: 15px;
      }

      .QuestDisplay_Pagination_Button {
        @include center-content;

        height: 39px;
        width: 39px;
        margin-left: 15px;

        border-radius: 26px;
        border: solid 2px $colorDGray;
        // background-color: $colorDDarkGray;
        color: $colorDGray;
        cursor: not-allowed;

        svg {
          width: 10px;
          height: auto;
        }

        &[disabled] {
          // cursor: not-allowed;
          pointer-events: none;
        }
      }

      .Clickable {
        border: none;
        background-color: $colorPurpleRay;
        cursor: pointer;
      }
    }
  }

  .QuestDisplay_List {
    display: flex;
    flex-direction: column;

    width: 100%;
    // height: 100%;
    // max-height: 80vh;

    // overflow-x: hidden;
    // overflow-y: auto;

    // padding-right: 20px;

    & > * {
      margin: 25px 0;
    }

    .QuestDisplay__Item {
      display: flex;

      width: 100%;
      // max-width: 900px;

      // height: 412px;

      background-color: $colorBackground;
      border-radius: 10px;

      padding: 40px;
      @media #{$mobileAndTabletOnly} {
        flex-direction: column;
        padding: 40px 20px;
      }

      .QuestDisplay__Item_Img {
        height: 321px;
        min-width: 321px;
        max-width: 321px;

        border-radius: 10px;
        object-fit: cover;
        @media #{$mobileAndTabletOnly} {
          align-self: center;
          height: 200px;
          min-width: 200px;
          max-width: 200px;
        }
      }

      .QuestDisplay__Item_Details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 390px;
        height: 100%;
        @media #{$mobileAndTabletOnly} {
          width: 80%;
        }

        margin-left: 39px;

        .QuestDisplay__Item_Title {
          font-size: 30px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.3px;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          @media #{$mobileAndTabletOnly} {
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 15px;
          }
        }

        .QuestDisplay__Item_Rewards {
          display: flex;
          flex-direction: column;

          margin-bottom: 5px;

          .QuestDisplay__Item_Rewards_Title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $colorCGray;

            margin-bottom: 10px;
          }

          .QuestDisplay__Item_Rewards_Details {
            display: flex;
            align-items: center;

            svg {
              width: 18px;
              height: auto;
            }

            .QuestDisplay__Item_Rewards_Details_Text {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: 0.24px;
              text-align: left;

              margin-left: 10px;
            }
          }
        }

        .QuestDisplay__Item_Actions {
          display: flex;
          // justify-content: space-between;
          flex-wrap: wrap;

          width: 100%;

          .QuestDisplay__Item_Actions_Action {
            @include center-content;
            @include transform-scale;

            width: 46px;
            height: 46px;

            margin: 0 10px 10px 0;

            background-color: $colorPurpleRay;
            border-radius: 8px;
            cursor: pointer;

            svg {
              width: 20px;
              height: 20px;
            }

            .QuestDisplay__Item_Actions_Campaign_Report {
              width: 100px !important;
            }
          }

          .QuestDisplay__Item_Actions_Game {
            @include transform-scale;

            display: flex;
            justify-content: space-evenly;
            align-items: center;

            height: 46px;
            width: 209px;

            margin: 0 10px 10px 0;

            background-color: $colorFluidPink;
            border-radius: 8px;
            color: $colorBackground;
            cursor: pointer;

            img {
              width: 36px;
              height: 36px;

              // object-fit: cover;
              border-radius: 6px;
            }

            .QuestDisplay__Item_Actions_Game_Title {
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: 0.24px;
              text-align: left;
              // color: $colorBackground;
            }

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .QuestDisplay__Item_View_Details {
          @include purple-gradient-button;

          width: 100%;
          height: 48px;
        }
      }
    }

    .QuestDisplay__Blank {
      @include center-content;
      flex-direction: column;

      min-height: 412px;
      max-height: 412px;
      width: 100%;

      border-radius: 14px;
      border: 1px dashed $colorSoftGray;

      & > * {
        margin: 12px;
      }

      svg {
        height: 85px;
        width: auto;
      }

      .QuestDisplay__Blank_Text {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: $colorWhite;
      }

      .QuestDisplay__Blank_Button {
        @include purple-gradient-button;
        @include transform-scale;

        height: 48px;
        width: 40%;
      }
    }
  }
}
