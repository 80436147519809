@import  '../../../utils/_variables';

$tablet: 1300px;
$tabletOnly: '(max-width: #{$tablet})';

@keyframes animateIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}


@keyframes animateOut {
  0% {
    display: block;
    opacity: 1;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}


@keyframes slideOver {
  0% {
    left: 0;
    width: 0%;
  }

  50% {
    width: 100%;
  }


  50% {
    width: 100%;
  }
}


.MuiTooltip-tooltip{
  background: #2B2E38 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  font-size: 12px !important;
  color: white !important;
  padding: 0px 13px !important;

  a {
    color: #3a74e0;
  }
}

html {
    scroll-behavior: smooth;
}
.QuestDetailPage {
  background-size: cover;

  .BottomGradient {
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $colorDarkGray);
  }

  // Buttons
  button {
    min-width: 100px;
    background: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    border-radius: 25px;
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 0 20px;
    height: 44px;

    &:hover {
      background: #3a74e0;
      background: linear-gradient(to bottom, #3a74e0, #3a74e0, #3a74e0, #3a74e0, #3a74e0);
    }

    &.download {
      width: 200px;
      margin: 25px 0 0 0;
    }

    &.edit, &.leave {
      background: none;
      border: 2px solid white;
    
      -moz-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      -webkit-transition: all .25s ease-in;
      transition: all .25s ease-in;

      &:hover {
        border: 2px solid #3a74e0;
        background-color: rgba(58, 116, 224, 0.25);
      }
    }

    &.leave {
      width: 160px;
      height: 48px;

      @media #{$mobileOnly}{
        width: 120px;
        height: 40px;
        margin: 0 20px 0 0;
      }
    }

    &.cancel {
      width: 160px;
      height: 48px;
      @media #{$mobileOnly}{
        width: 120px;
        height: 40px;
        margin: 0;
      }
    }

    &.leave-quest {
      width: 160px;

      @media #{$mobileOnly}{
        width: 100%;
        margin: auto;
      }
    }

    &.left {
      margin: 20px 0 30px 0;
      background: linear-gradient(to bottom, #3a74e0, #4a6fe6, #5d69ea, #7061ec, #8358ed);
      width: 200px;
    }

    @media #{$mobileOnly}{
      font-size: 14px;
      min-width: 80px;
      height: 40px;
      margin-left: 8px;
      padding: 0;
    }
  }


  .QuestDetailPage__container{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding-top: 110px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-right: 0;
    padding-left: 0;
    display: flex; 

    .noQuestFound {
      font-size: 28px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 100px 0 0 0;
      text-align: center;
    }

    @media #{$mobileOnly}{
      width: auto;
      margin: 0 12px;
    }

    .missingRequirementsBar {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 3px;
      background-image: linear-gradient(to right, #fd8d82 0%, #6236ff 100%);
      align-items: center;
      padding: 24px 0;

      span {
        font-size: 16px;
        text-align: center;
        margin: auto;
      }

      ul {
        max-width: 600px;
        margin: 14px 0 0 0;

        li {
          font-size: 16px;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }

        @media #{$mobileOnly}{
          max-width: 250px;
        }

      }
    }
  }

  .Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    background: rgba(0,0,0,.5);
    align-items: flex-start;
    display: flex;
    align-items: center;

    &.active{
      visibility: visible;
      opacity: 1;
      .Modal__layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      .Modal__wrapper{
        width: 90%;
        max-width: 825px;
        background: #202227;
        padding: 0;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
        top: -50%;
        transform: scale(1) translateY(calc(50% + 30vh));
        margin: 10vh 0;
        border-radius: 5px;
        padding: 20px;
        border: 2px solid $colorDarkGray;
        display: flex;
        flex-direction: column;

        &.LeaveQuest {
          min-height: 225px;

          i.fa-spinner {
            margin: 70px 0 0 0;
          }
        }

        @media #{$mobileOnly}{
          transform: none !important;
          top: 0 !important;
          width: 100vw;
          height: 100vh;
          position: absolute;
          margin: 0;

          h1 {
            font-size: 20px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .StatusBarContainer {
    .StatusBar {
      height: 68px;
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(255,255,255,0.1);
      border-bottom: 1px solid rgba(255,255,255,0.1);
      z-index: 10;

      &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        background: $colorBDarkGray;
        padding: 0 5%;
      }

      #status-bar-content-mobile {
        height: 0px;
        overflow: hidden;
      }
    }

    .left {
      display: flex;
      align-items: center;
      min-width: 50%;
    }

    h2 {
      margin-right: 20px;
      font-size: 20px;
    }

    i {
      color: $colorLightGray;
      margin: 10px;
    }

    .status {
      font-size: 12px;
      font-weight: 700;
      margin: 0 20px;
      letter-spacing: 1px;

      &.error {
        color: red;
      }

      &.completed {
        color: $colorLifeGreen;
      }
    }

    .payment {
      margin: 0 20px;
    }

    .paymentContainer {
      align-items: center;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.25));
      height: 68px;
      display: flex;
      justify-content: space-evenly;

      h3 {
        font-size: 20px;
      }


      &.freeKey {
        h3 {
          font-size: 15px;
        }
      }
    }

    .streamingPlatform {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0 20px;

      .fa-twitch {
        font-size: 24px;
      }
    }

    @media #{$mobileOnly}{
      .StatusBar {
        border-top: 1px solid rgba(255,255,255,0.1);
        justify-content: center;
        flex-direction: column;
        height: auto;
        align-items: normal;
      }
      h2 {
        text-align: center;
        margin: 10px 0 15px 0;
      }

      img {
        margin: 10px auto;
        color: rgba(255, 255, 255, 0.5);
      }

      .paymentContainer {
        width: 76px;
        width: 76px;
        height: 43px;
        font-size: 20px;

        .payment {
          margin: 0 6px;
        }
      }

      .streamingPlatform {
        font-size: 14px;
        margin: 5px 0 0 10px;

        .fa-twitch {
          font-size: 18px;
        }
      }

      .status {
        font-size: 10px;
        margin: 20px 20px;
        text-align: center;
      }
    }
  }

  .NavScrollContainer {
    @media #{$tabletOnly}{
      display: none;
    }
  }

 .QuestDetailPage__scrollContainer {
    display: flex;
    height: 100%;
    display: flex;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media #{$mobileOnly}{
      padding-top: 0px;
    }

    .NavContainer {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 215px);
      padding: 0 72px 0 0;

      &.sticky {
        position: fixed;
      }

      &.stop {
        position: absolute;
      }

      .streamSection {
        h2 {
          margin: 0 0 0 15px;
          display: flex;
          align-items: center;
        }
      }

      ul {
        list-style: none;
        padding-left: 65px;

        li {
          color: $colorLightGray;
          margin: 15px 0px;
          font-weight: 600;
          display: flex;
          align-items: center;

          .navItem {
            text-decoration: none;
            color: $colorLightGray;
            font-size: 14px;
            cursor: pointer;

            &.rejected {
              color: red;
            }
          }

          &.active {
            color: $colorPurpleRay;

            .navItem {
              color: $colorPurpleRay;
            }
          }

          i {
            color: red;
          }
        }
      }

      span {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 17px;
        font-size: 20px;
        font-weight: 900;
        background: rgba(0,0,0,0.3);
        border-radius: 50%;

        &.active {
          background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
        }
      }

      h2 {
        span {
          padding: 20px;

          &.active {
            background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
            border-radius: 50%;
            height: 48px;
            width: 48px;
            display: inline-block;
          }
        }
      }
    }

    .ReqContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 0 50px 0;

      h1 {
        margin: 30px 0 10px 0;
      }


      @media #{$mobileOnly}{ 

        h1 {
          text-align: center;
          font-size: 20px;
        }
      }

      .CommissionBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 104px;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.35);
        width: 100%;
        padding: 20px;

        @media #{$mobileOnly}{
          flex-direction: column;
          text-align: center;
          padding: 12px;
        }

        h2 {
          font-size: 20px;
          margin: 0 0 14px 0;
        }

        .static-content {
          display: flex;
          flex-direction: column;
          padding: 0 20px 0 0;
        }

        .variable-content {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          @media #{$mobileOnly}{
            flex-direction: column;
          }

          .values {
            display: flex;
            flex-direction: row;

            @media #{$mobileOnly}{
              align-self: center;
            }
          }

          .trackingLink {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            margin: 0 0 0 12px;

            .label {
              text-transform: uppercase;
              font-size: 12px;
              letter-spacing: 1px;
              color: #ffffff;
              font-weight: bold;
              margin-top: 18px;
            }

            input {
              height: 44px;
              background-color: #202227;
              color: #ffffff;
              border-radius: 3px;
              border: solid 2px #2c3239;
              margin: 10px 0 0 0 ;
              padding: 0px;
              padding: 0px 10px;
              max-width: 296px;
              min-width: 200px;
            }
          }

          @media #{$mobileOnly}{
            .labelLink {
              text-align: left;
            }

            .label {
              font-size: 10px;
            }
            
            input {
              font-size: 12px;
            }
            
            .trackingLink {
              margin: 20px auto 0px auto;
            }
          }
        }
      }

      .valueTrack {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        min-width: 64px;
        height: 64px;
        border-radius: 3px;
        border: solid 1px #2c3239;
        background-color: #202227;
        margin: 17px 12px 0 0;
        padding: 4px 12px;

        .count {
          font-size: 30px;
          font-weight: 900;
          margin: 0;
        }

        .description {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          color: $colorGray;
          letter-spacing: 1px;
        }
      }

      .RequirementContainer {
        position: relative;

        .requirementsCheckbox {
          float: left;
          padding: 0 10px;
        }

        .timeline {
          height: 100%;
          display: block;
          position: absolute;
          width: 40px;

          .topBar {
            margin: 35px 0 0 0;
            width: 40px;
            height: 15px;
            background-image: linear-gradient(90deg, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 19px, rgba(131, 133, 140, 1) 19px, rgba(131, 133, 140, 1) 20px, rgba(0,0,0,0) 20px, rgba(0, 0, 0, 0) 100%);
            
            &.first {
              background-image: none;
            }
          }

          .bottomBar {
            margin: 22px 0 0 0;
            width: 40px;
            height: calc(100% - 20px);
            background-image: linear-gradient(90deg, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 19px, rgba(131, 133, 140, 1) 19px, rgba(131, 133, 140, 1) 20px, rgba(0,0,0,0) 20px, rgba(0, 0, 0, 0) 100%);
            
            &.last {
              background-image: none;
            }
          }

          @media #{$mobileOnly}{
            width: 20px;

            .requirementsCheckbox {
              margin: 0px 0 0 2px;
              padding: 0px;
            }

            .topBar {
              margin: 25px 0 0 0;
              background-image: linear-gradient(90deg, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 7px, rgba(131, 133, 140, 1) 7px, rgba(131, 133, 140, 1) 8px, rgba(0,0,0,0) 8px, rgba(0, 0, 0, 0) 100%);
            }

            .bottomBar {
              margin-top: 12px;
              background-image: linear-gradient(90deg, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 7px, rgba(131, 133, 140, 1) 7px, rgba(131, 133, 140, 1) 8px, rgba(0,0,0,0) 8px, rgba(0, 0, 0, 0) 100%);
            }

            img.checkbox {
              width: 12px;
            }
          }
        }
      }

      .RequirementBox {
        background-image: linear-gradient(90deg, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 80px, rgba(0, 0, 0, 0.35) 80px, rgba(0, 0, 0, 0.35) 100%);
        display: flex;
        margin: 20px 0px 0px 64px;
        border-radius: 12px;
        overflow: hidden;

        .RequirementBackground {
          top: 0;
          bottom: 0;
          position: absolute;
          width: 0%;
          left: 0;
          right: 0;
          margin: 20px 0px 0px 64px;
          background: linear-gradient(90deg, black 0px, black 80px, #202227 80px, #202227 100%);
          opacity: 1;
          z-index: 1;
          border-radius: 12px;
        }

        &.in-review {
          background-image: linear-gradient(90deg, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 80px, rgba(32, 34, 39, 1) 80px, rgba(32, 34, 39, 1) 100%);
          
          @media #{$mobileOnly}{
            background: rgba(32, 34, 39, 1);
          }
        }

        @media #{$mobileOnly}{
          background: rgba(0, 0, 0, 0.35);
          margin: 20px 0px 0px 25px;
        }

        span {
          font-size: 16px;
        }

        &.rejected {
          border: 1px solid red;

          span.note {
            color: red;
          }
        }

        .icon {
          border-radius: 50%;
          background: $colorBDarkGray;
          min-width: 64px;
          height: 64px;
          display: flex;
          align-content: center;
          justify-content: center;
          margin: 8px 8px 0 8px;
          z-index: 2;

          img {
            width: 30px;
          }

          @media #{$mobileOnly}{
            min-width: 40px;
            height: 40px;
            margin: 8px 0px 0 8px;

            img {
              width: 20px;
            }
          }
        }

        .imageIcon {
          width: 80px;
          height: 80px;
          background-size: cover;
          background-position: center;
          z-index: 2;
          border-radius: 12px 0 0 0;
          &.schedule {
            width: 50px;
            margin: 10px 15px 10px 15px;
            height: 50px;
            @media #{$mobileOnly}{
              width: 30px;
              background-size: contain;
              background-repeat: no-repeat;
              height: 30px;
              transform: translateX(5px) translateY(12px);
              margin: 0px;
            }
          }

          @media #{$mobileOnly}{
            width: 48px;
            height: 48px;
            min-width: 48px;
          }
        }

        span.content-right {
          margin: 27px 16px 20px auto;
          display: flex;
          flex-direction: row;
          z-index: 2;

          .collapseArea {
            position: absolute;
            width: 50px;
            height: 50px;
            right: 10px;
          }
          
          img.collapseToggle {
            width: 16px;
            height: 8px;
            margin: 9px 4px 0px 0px;
            // display: none;
            opacity: 0;
          }

          .toolTip {
            color: #3a74e0;
            border: 2px solid #3a74e0;
            border-radius: 50%;
            min-width: 25px;
            height: 25px;
            text-align: center;
            font-weight: 600;
            margin: 0 0 0 20px;
            opacity: 1;
          }

          @media #{$mobileOnly}{
            display: none;
          } 

          &.collapseable {
            .toolTip {
              animation: animateOut 1s ease-out;
              opacity: 0;
              display: none;
            }

            img.collapseToggle {
              animation: animateIn 1s ease-out;
              opacity: 1;
              display: block;
            }
          }
        }

        .content {
          padding: 25px 20px;
          display: flex;
          flex-direction: column;
          z-index: 2;
          max-width: 80%;

          .label {
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            color: #ffffff;
            font-weight: bold;
          }

          .description {
            p {
              margin: 0;
            }
          }

          h2 { 
            font-size: 20px;
            margin: 0;
          }

          span {
            font-size: 14px;
            color: #ffffff;
            margin: 25px 0 0 0;
            color: rgba(255, 255, 255, 0.9);

            &.reviewStatus {
              color: $colorPurpleRay;
              margin: 0 0 0 20px;
              width: 190px;

              &.error {
                color: red;
              }

              @media #{$mobileOnly}{
                display: none;
              }
            }

            &.reviewStatus-mobile {
              color: $colorPurpleRay;
              margin: 15px 0 0 -47px;
              width: 190px;

              &.error {
                color: red;
              }

              @media #{$tabletAndLarger}{
                display: none;
              }
            }

            &.error {
              color: red;


              @media #{$mobileOnly}{
                width: 80%;
              }
            }
          }

          @media #{$mobileOnly}{
            padding: 16px 12px;
            max-width: 90%;

            h2 {
              font-size: 16px;
            }

            span {
              font-size: 12px;
              // margin: 15px 0 0 -45px;

              &.description {
                margin: 25px 0 0 -45px;
              }
            }

            .label { 
              font-size: 10px;
              margin-left: -45px;
            }
          }

          .timeBox {
            display: flex;
            flex-direction: row;

            @media #{$mobileOnly}{
              margin: 0 0 0 -45px;
            }
          }

          .timeStatus {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            @media #{$mobileOnly}{
              margin: 0 0 0 -45px;
            }

            span {
              margin: 10px 5px 0 0 !important;

              &.indicator {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $colorGray;
                margin: 0 10px 0 0;

                &.complete {
                  background: $colorLifeGreen;
                }
              }
            }
          }

          .copyContent{
            display: flex;
            align-items: center;
            width: 600px;
            margin: 10px 0 0 0;

            input {
              height: 44px;
              background-color: #202227;
              color: #ffffff;
              border-radius: 3px;
              border: solid 2px #2c3239;
              margin: 0px;
              padding: 0px;
              padding: 0px 10px;
              max-width: 296px;
              min-width: 200px;

              @media #{$mobileOnly}{ 
                width: 215px;
              }

              &.error {
                border: solid 1px red;
              }

              &:focus {
                background-color: #202227 !important;
              }

              &::placeholder {
                color: #83858c;
              }
            }

            @media #{$mobileOnly}{ 
              font-size: 12px;
              width: 400px;
              margin: 10px 0 0 -50px;
            }
          }
        }
      }
    }
  }
}