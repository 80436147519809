@import "../../utils/_variables";

.Main__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerHeight;
  left: 0;
  padding: 0 5vw;
  position: absolute;
  top: 0;
  width: 100%;

  .language {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &.white {
    background: $colorBackground_light;

    .navigation {
      &.open {
        @media #{$mobileOnly} {
          background: $colorBackground2_light;
        }
      }

      .navigation-right {
        padding-right: 15px;
      }

      ul li a {
        color: #000;
      }
    }

    .toggle-mobile-menu {
      span {
        background: $colorLightGray;
      }
    }
  }

  .Main__Header__wrapper {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    height: 100%;
    z-index: 10;

    &.forceFullWidth {
      margin: 0 auto;
      max-width: calc(100% - 60px);
    }

    &.constrain {
      margin: 0 auto;
      max-width: $macroScreen;
    }
  }

  .borderBottom {
    border-bottom: 1px solid transparent;
  }

  .logo {
    align-self: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 39px;
    margin: 6px 0px 10px 0px;
    min-width: 120px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      min-width: 90px;
    }

    @media screen and (min-width: 1074px) {
      // 1024 + (25 * 2)
      margin-left: 0px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  &.basic-menu {
    padding: 0 5vw;

    .Main__Header__wrapper {
      width: 100%;

      @media screen and (min-width: 1024px) {
        margin: 0 auto;
        max-width: 1280px;
      }
    }

    .logo {
      @media screen and (min-width: 768px) {
        margin: 6px 0px 10px 0px;
      }
    }
  }
}
