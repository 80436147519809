@import "../../../utils/_variables";

.SignIn {
  .language-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 38px;
    padding-right: 143px;
    z-index: 999;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .ModalMaster {
    .Modal {
      .Modal__wrapper {
        .Modal__content {
          padding-bottom: 27px;
        }
      }
    }
  }
}
