
//text-align
.tr {
    text-align: right;
}
.tc {
    text-align: center;
}
.tl {
    text-align: left;
}