@import '../../utils/_variables';

$itemHeight: 84;
$offset: 2;

.quest-item {
  align-items: flex-start;
  border-bottom-color: #2b2e38;
  border-bottom-style: solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: Roboto;
  justify-content: space-between;
  margin: 0 auto;
  width: 93%;

  &.grey {
    color: #83858c;
  }

  @include vw-pixels((
    'border-bottom-width': $offset,
    'height': $itemHeight + $offset,
    'line-height': $itemHeight + $offset,
  ), $vwPixelsScale);

  @media #{$macroScreens} {
    border-bottom-width: 2px;
    height: 86px;
    line-height: 86px;
  }

  .title {
    // flex: 3;
    flex: 4;
    font-weight: 900;
    margin-right: 10px;
    min-width: calc(30% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // width: 30%;
    // word-wrap: normal;

    @include vw-pixels(('font-size': 20), $vwPixelsScale);

    @media #{$mobileOnly} {
      line-height: 1.61;

      @include vw-pixels(('padding': 20));
    }

    @media #{$macroScreens} {
      font-size: 20px;
    }
  }

  .Quest__ManagedBy {
    align-items: center;
    background-image: linear-gradient(to bottom, #e8cdfe, #b7bbfd);
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    padding: 0 5px;
    // width: 10%;

    svg {
      height: auto;
      margin: 0 10px;
      width: clamp(20px, 5vw, 30px);
    }

    .Quest__ManagedBy__Label {
      color: $colorBlack;
      font-size: 12px;
      font-size: .9vw;
      font-weight: bold;
      line-height: normal;
      text-align: center;
    }

    @include vw-pixels((
      'font-size': 18,
      'height': $itemHeight,
    ), $vwPixelsScale);


    @media #{$macroScreens} {
      font-size: 18px;
      height: 84px;
    }
  }

  .payment-amount {
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.25));
    display: flex;
    flex: 1;
    font-weight: 900;
    justify-content: center;
    text-align: center;
    // width: 10%;

    @include vw-pixels((
      'font-size': 25,
      'height': $itemHeight,
    ), $vwPixelsScale);

    &.free-key {
      @include vw-pixels(('font-size': 20), $vwPixelsScale);
    }

    @media #{$macroScreens} {
      font-size: 25px;
      height: 84px;

      &.free-key {
        font-size: 20px;
      }
    }
  }

  .platform-icons {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: 50%;
    transform: translateY(-43%);
    // width: 10%;

    div {
      height: auto;
      width: 15%;
    }

    div:nth-child(2) {
      margin-left: 20%;
    }
  }

  .date-range {
    flex: 1.5;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    // width: 16%;

    @include vw-pixels((
      'font-size': 15.5,
      'margin-left': 10
    ), $vwPixelsScale);

    @media #{$macroScreens} {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  .button {
    align-items: center;
    display: flex;
    float: right;
    justify-content: center;
    text-decoration: none;

    @include vw-pixels((
      'border-radius': 26,
      'height': 40,
      'margin-bottom': 24,
      'margin-left': 40,
      'margin-right': 0,
      'margin-top': 20,
      'width': 160
    ), $vwPixelsScale);

    &.applied {
      background-color: rgba(255, 255, 255, 0.11);
      border: solid 2px rgba(255, 255, 255, 0.1);
    }

    &.ineligible {
      background-color: rgba(224, 32, 32, 0.2);
      border: solid 2px rgba(255, 0, 0, 0.25);
    }

    &.view-details {
      background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
    }

    .text {
      font-weight: 900;
      @include vw-pixels((
        'font-size': 16,
      ), $vwPixelsScale);
    }
  }
}

@media #{$mobileOnly} {
  .quest-item {
    background-color: #202227;
    border: solid 1px #2b2e38;
    display: flex;
    flex-direction: column;
    height: unset;

    @include vw-pixels((
      'border-radius': 12,
      'margin-bottom': 40,
      'margin-top': 20,
      'width': 420
    ), $vwPixelsScale);

    .date-range {
      line-height: normal;
      margin-right: 0;
      order: -1;
      width: auto;

      @include vw-pixels((
        'font-size': 16,
        'margin-bottom': 13,
        'margin-left': 28,
        'margin-top': 32
      ), $vwPixelsScale);
    }

    .title {
      line-height: normal;
      padding: 0;
      width: auto;

      @include vw-pixels((
        'font-size': 30,
        'margin-bottom': 21,
        'margin-left': 28,
        'margin-right': 30,
        'margin-top': 13
      ), $vwPixelsScale);
    }

    .Quest__ManagedBy {
      padding: 10px;
      width: 100%;
  
      .Quest__ManagedBy__Label {
        font-size: 13px;
        width: 62%;
      }
    }

    .platform-icons {
      margin-top: 8px;
      top: 0;
      transform: unset;
      width: 38%;

      @include vw-pixels((
        'height': 64,
        'line-height': 64
      ), $vwPixelsScale);
    }

    .payment-amount {
      display: inline-block;
      width: 100%;

      @include vw-pixels((
        'height': 64,
        'line-height': 64,
        'padding-left': 28
      ), $vwPixelsScale);
    }

    .button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;

      @include vw-pixels((
        'margin-bottom': 30,
        'width': 365
      ), $vwPixelsScale);
    }
  }

}

@media #{$macroScreens} {
  .button {
    height: 40px !important;
    margin: 24px 0 !important;
    width: 180px !important;

    .text {
      font-size: 16px !important;
    }
  }
}