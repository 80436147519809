@import "../../../../utils/_variables";

.ReviewApplicantsDeliverables {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;

  .ReviewApplicantsDeliverables_Header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-stretch: normal;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.24px;
    line-height: 1.25;
    text-align: left;
    width: 100%;

    .review {
      font-size: 17px;
      width: 100%;
    }

    @media #{$mobileAndTabletOnly} {
     font-size: 18px;
    }

    // @include vw-pixels((font-size: 15));

    @media #{$desktopAndLarger} {
      font-size: 20px;
    }

    .ReviewApplicantsDeliverables_Header_Tab {
      cursor: pointer;
      margin-right: 10px;
    }

    .ActiveTab {
      border-bottom: 2px solid $colorSoftPurple;
      transition: border-bottom 0.2s ease-in-out;
      transition: color 0.2s ease-in-out;
    }

    .InactiveTab {
      color: $colorLightGray;
      transition: border-bottom 0.2s ease-in-out;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $colorWhite;
      }
    }
  }
}
