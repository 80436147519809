@import "../../../utils/_variables";

.BrandPayment {
  @include col-center;
  width: 100%;

  .PaymentTitle {
    font-size: 26px;
    margin: 20px 0px;
  }
}
.Account {
  padding: 40px;
  margin-top: 50px;
  background-color: #202227;
}
