@import "../../../../utils/_variables";

.ActiveItems {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  width: 100%;

  .ActiveItems__Title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .ActiveItems__Title__Title {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.24px;
      text-align: left;
    }

    .ActiveItems__Title__Subtitle {
      color: $colorCyan;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      text-align: right;
    }
  }

  .ActiveItems__Blank {
    @include center-content;
    background-color: $colorBackground;
    border-radius: 8px;
    flex-direction: column;
    height: 348px;
    width: 100%;

    & > * {
      margin: 10px 0;
    }

    svg {
      height: auto;
      width: 66px;
    }

    .ActiveItems__Blank_Text {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.24px;
      text-align: center;
      width: 70%;
    }

    .ActiveItems__Blank_Button {
      @include purple-gradient-button;
      @include transform-scale;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      height: 48px;
      letter-spacing: normal;
      text-align: center;
      width: 80%;
    }
  }

  .ActiveItems__Item {
    align-items: center;
    background-color: $colorBackground;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    max-height: 75px;
    padding: 13px 15px;

    @media #{$desktopTight} {
      max-height: none;
    }


    .ActiveItems__Item_Details {
      align-items: center;
      display: flex;
      max-width: fit-content;

      .ActiveItems__Item_Image {
        border-radius: 8px;
        height: 46px;
        margin-right: 6px;
        object-fit: cover;
        width: 46px;
      }

      .ActiveItems__Item_Title {
        color: $colorWhite;
        font-size: 80%;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        // letter-spacing: 0.24px;
        text-align: left;
      }
    }

    .ActiveItems__Item_Buttons {
      display: flex;

      .ActiveItems__Item_Button {
        @include center-content;
        @include transform-scale;
        // background-color: $colorWhite;
        border: solid 1px $colorWhite;
        border-radius: 8px;
        cursor: pointer;
        height: 40px;
        // margin: 0 13px 0 49px;
        margin-left: 13px;
        // padding: 14px 13px;
        width: 40px;

        svg {
          height: auto;
          width: 50%;
        }
      }
    }
  }

  .ActiveItems__View_All {
    @include white-border-button;
    height: 48px;
    margin: 26px 0 0;
    width: 100%;

    a {
      align-items: center;

      display: flex;

      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      height: 100%;
      justify-content: center;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      width: 100%;
    }
  }
}
