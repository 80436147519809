@import "../../utils/_variables";

.shareBtn {
  width: 52px;
  height: 52px;
  border-radius: 4px;
  border: solid 2px #2c3239;
  background-color: #202227;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  

  &:hover {
    box-shadow: inset 0 0 24px 30px rgba(255, 255, 255, 0.08);
    transition: 0.8s ease;
  }
}


