@import "../../../../utils/_variables";
.PortalRight {
  display: flex;
  flex-direction: column;
  // margin-left: 20px;
  min-width: 300px;
  padding: 18px;
  width: 30%;

  @media #{$mobileAndTabletOnly} {
    width: 100%;
  }
  
}
