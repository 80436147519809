@import '../../../../../utils/_variables';

.Gallery_Header {
  font-size: 26px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.26px;
  text-align: left;
}

.Gallery_Items {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .Gallery_Card {
    @include center-content;

    max-width: 180px;
    position: relative;

    background-color: $colorSoftGray;
    border-radius: 8px;

    transition: filter 0.3s ease-in-out;
    
    .Gallery_Card_Img {
      min-height: 180px;
      max-height: 180px;
      min-width: 180px;
      max-width: 180px;

      border-radius: 8px;
      object-fit: cover;
    }

    .Gallery_Card_Overlay {
      display: none;
      align-items: center;
      justify-content: center;

      @include background-blur-and-border;

      position: absolute;

      height: 44px;
      width: 44px;

      border-radius: 12px;

      svg {
        height: 18px;
        width: auto;
      }
    }

    &:hover {
      .Gallery_Card_Img {
        filter: brightness(50%);
      }

      .Gallery_Card_Overlay {
        display: flex;

        cursor: pointer;
      }
    }
  }
}

.Gallery_Dropzone {
  @include center-content;
  flex-direction: column;

  width: 100%;

  padding: 55px;

  border: dashed 2px $colorSoftGray;
  border-radius: 14px;
  cursor: pointer;

  & > * {
    margin: 9px 0;
  }

  svg {
    height: auto;
    width: 62px;
  }

  .Gallery_Dropzone_Button {
    @include purple-gradient-button;
  
    height: 48px;
    width: 30%;
  }
}
