@import "../BrandService.scss";

// *** Mixins ***
// centerFlex
// vertFlex
// spaceFlex
// brandTwoGrid
// brandMedText
// brandPurpleHeroText
// brandSubtitleWhiteText

$titleMargin: 40px;

.scroll-for-more-brand {
  @include centerFlex;
  color: rgba(255, 255, 255, 0.75);
  font-family: Roboto;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 100%;
  letter-spacing: 1px;
  line-height: normal;
  position: absolute;
  text-align: center;

  .scroll-notice-flex {
    align-content: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;

    @media #{$tabletOnly} {
      bottom: 10vh;
    }

    @media #{$desktopAndLarger} {
      top: 90vh;
    }

    @media #{$mobileOnly} {
      bottom: 10vh;
    }
  }
}

.nice-slide-c-container {
  justify-content: center;
  padding-top: 4%;
  z-index: 2;
  @include brandTwoGrid;

  .brand-computer {
    @media #{$mobileOnly} {
      margin-bottom: 20px;
      max-width: 80vw;
    }
  }

  .stacked-nice-text {
    @include brandMedText;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media #{$tabletOnly} {
      max-width: 30vw;
    }

    @media #{$desktopAndLarger} {
      margin-right: 60px;
      max-width: 450px;
    }

    @media #{$mobileOnly} {
      justify-content: space-between;
      max-width: 80vw;
    }
  }

  :nth-child(2) {
    margin-top: 20px;
  }
}

.brand-vert-stack {
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  @include vertFlex;

  div {
    justify-content: flex-start;
    margin-bottom: 12px;
    text-align: left;
  }

  :nth-child(3) {
    margin-top: 10px;
  }
}

.brand-vert-stack-center {
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  @include vertFlex;

  div {
    margin-bottom: 20px;
    text-align: center;
  }
}

.brand-angle-svg {
  @include centerFlex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 2%;
  padding-top: 1.5%;
  z-index: 0;
}

.global-platform-background {
  @include centerFlex;
  background-position: left;
  /* Center the image */
  background-size: cover;
  justify-content: flex-start;

  @media #{$mobileOnly} {
    background-position: left;
  }

  @media #{$tabletOnly} {}
}

.brand-d-noiz-title {
  @include medLargeHeadline;
  margin-bottom: 10vh;

  @media #{$desktopAndLarger} {
    font-size: 60px;
  }
}

.brand-title {
  @include medLargeHeadline;

  @media #{$mobileOnly} {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.brand-d-noiz-vcontainer {
  @include vertFlex;
  align-content: center;
  justify-content: center;
}

.brand-d-noiz-horiz-container {
  @include spaceFlex;
  justify-content: space-evenly;
  width: 100%;

  @media #{$mobileOnly} {
    align-items: flex-start;
    display: grid;
    grid: 150px / auto auto;
    grid-auto-rows: auto;
    grid-gap: 10%;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;
  }

  @media #{$tabletOnly} {
    align-items: flex-start;
    display: grid;
    grid: 150px / auto auto;
    grid-auto-rows: auto;
    grid-gap: 10%;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;
  }
}

.brand-d-writing-pod {
  @include vertFlex;
}

.brand-d-subtitle {
  @include brandSubtitleWhiteText;
}

.brand-d-purple-counter {
  @include brandPurpleHeroText;
}

.slide-e-container {
  @include spaceFlex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  z-index: 5;

  &>div:nth-of-type(2) {
    align-items: center;
    align-items: center;
    display: flex;
    flex-basis: 50%;
    justify-content: center;
  }

  @media #{$mobileOnly} {
    flex-direction: column-reverse;
    justify-content: flex-start;

    >div {
      display: flex;
      justify-content: center;
      margin: 0;
      max-width: $theMaxWidth;
      overflow: visible;
    }

    &>div:nth-of-type(1) {
      margin-bottom: 140px;
    }

    img {
      height: auto;
      object-fit: scale-down;
      width: 100%;
    }
  }

  @media #{$tabletOnly} {
    >div {
      max-width: 40%;
    }
  }

  @media #{$desktopAndLarger} {
    >div {
      max-width: 40%;

      img {
        max-width: 70%;
      }
    }
  }
}

.slide-f-container {
  @include spaceFlex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  z-index: 5;

  >div {
    align-items: flex-start;
    display: flex;
    flex-basis: 50%;
    justify-content: flex-start;
  }

  &>div:nth-of-type(1) {
    align-items: center;
    display: flex;
    flex-basis: 50%;
    justify-content: center;
  }

  .brand-micro-image {
    max-width: 450px;
    min-width: 250px;
    width: 25vw;
  }

  @media #{$mobileOnly} {
    align-items: center;
    flex-direction: column;
    justify-content: center;

    >div {
      display: flex;
      justify-content: center;
      margin: 0;
      max-width: $theMaxWidth;
    }

    &>div:nth-of-type(1) {
      flex-basis: 40%;
    }
  }

  @media #{$tabletOnly} {
    >div {
      max-width: 40%;
    }
  }

  @media #{$desktopAndLarger} {
    >div {
      max-width: 40%;
    }
  }
}

// SLIDE H

.slide-h-background {
  background-image: linear-gradient(to bottom, #3a74e0, #8052f5 100%);
  clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
  width: 100%;

  @media #{$mobileOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  }

  @media #{$tabletOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  }
}

.slide-h-background-2 {
  background-image: linear-gradient(to bottom, #3a74e0, #8052f5 20%);
  width: 100%;

  @media #{$mobileOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  }

  @media #{$tabletOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
  }
}

.slide-h-container {
  @include spaceFlex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  z-index: 5;

  .brand-title {
    font-size: 38px;
    padding-bottom: $titleMargin;

    @media #{$desktopAndLarger} {
      font-size: 48px;
    }

    @media #{$mobileOnly} {
      font-size: 38px;
    }

    @media #{$tabletOnly} {
      font-size: 38px;
    }
  }

  @media #{$mobileOnly} {
    justify-content: flex-start;
    padding-bottom: 170px;
    padding-top: 170px;
  }

  @media #{$tabletOnly} {
    justify-content: flex-start;
    padding-top: 100px;
  }

  @media #{$desktopAndLarger} {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .brand-logo-grid {
    align-items: flex-start;
    box-sizing: border-box;
    display: grid;
    grid: 130px / auto auto auto auto auto;
    grid-auto-rows: auto;
    grid-gap: 5%;
    grid-template-rows: auto;
    justify-content: space-evenly;
    width: 100%;

    >div {
      min-width: 130px;
    }

    @media #{$mobileOnly} {
      grid: 100px / auto auto;

      >div {
        max-width: 100px;
      }
    }

    @media #{$tabletOnly} {
      grid: 150px / auto auto auto;
      grid-row-gap: 10%;
      grid-template-rows: .5fr .5fr .5fr .5fr .5fr;

      >div {
        max-width: 150px;
      }
    }

    @media #{$desktopAndLarger} {
      >div {
        max-width: 100px;
      }
    }
  }
}

// SLIDE H

.grey-brand-body-text {
  @include greyBrandBodyText;
}

.brand-services-trophy-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;

  @media #{$mobileOnly} {
    text-align: left;
  }

  @media #{$tabletOnly} {
    text-align: left;
  }
}

.background-i {
  clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);

  @media #{$mobileOnly} {
    clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
  }

  @media #{$tabletOnly} {
    clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
  }

  // slide I
}

.slide-i-container {
  .background-i {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: 0;
  }

  @include vertFlex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  z-index: 5;

  :nth-child(1) {
    margin-bottom: 20px;
  }

  .cascade-fade-g {
    opacity: 0;
  }

  @media #{$mobileOnly} {
    justify-content: flex-start;
    padding-top: 100px;

    :nth-child(1) {
      margin-bottom: 20px;
    }
  }

  @media #{$tabletOnly} {
    justify-content: flex-start;
    padding-top: 100px;

    :nth-child(1) {
      margin-bottom: 20px;
    }
  }

  .brand-i-title {
    @include medLargeHeadline;
    margin-bottom: $titleMargin;

    @media #{$mobileOnly} {
      align-items: center;
      display: flex;
      font-size: 38px;
      justify-content: center;
      margin-top: 20px;
      text-align: center;
    }

    @media #{$tabletOnly} {
      align-items: center;
      display: flex;
      font-size: 38px;
      justify-content: center;
      margin-top: 20px;
      text-align: center;
    }

    @media #{$desktopAndLarger} {
      font-size: 48px;
    }
  }

  .brand-i-grid {
    display: grid;
    gap: 5% 5%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.2fr 0.2fr 0.2fr;

    // grid-template-areas: ". ." ". ." ". .";
    @media #{$mobileOnly} {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0px 1%;
      justify-content: flex-start;
    }

    @media #{$tabletOnly} {
      align-items: center;
      gap: 0px 0px;
      grid-template-areas: ". ." ". .";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 0.2fr;
      justify-content: flex-start;
    }

    @media #{$desktopAndLarger} {}
  }

  .brand-text-stack {
    align-items: flex-start;
    flex-basis: 1fr 1fr;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-width: 450px;
    z-index: 2;
    @include vertFlex;

    @media #{$mobileOnly} {
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 255px;
    }

    @media #{$tabletOnly} {
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 255px;
    }

    .row-flex {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-width: 700px;
      padding: 15px;

      :nth-child(1) {
        min-width: 100px;
      }

      :nth-child(2) {
        flex-basis: 80%;
      }

      @media #{$mobileOnly} {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        text-align: center;
      }

      @media #{$tabletOnly} {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        text-align: center;
      }
    }

    .text-flex {
      @include vertFlex;
      align-items: flex-start;
      height: 100%;
      justify-content: flex-start;
      padding: 5px;

      @media #{$mobileOnly} {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        text-align: center;
      }

      @media #{$tabletOnly} {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        text-align: center;
      }

      .title {
        color: #ffffff;
        font-family: Roboto;
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 300;
        letter-spacing: normal;
        line-height: normal;
        margin-bottom: 10px;

        @media #{$mobileOnly} {
          font-size: calc(15px + 2.5vw);
        }

        @media #{$tabletOnly} {
          font-size: calc(15px + 2.5vw);
        }
      }

      .body {
        color: #83858c;
        font-family: Roboto;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.25;

        @media #{$mobileOnly} {
          font-size: calc(13px + 1.3vw);
          line-height: 1.5;
        }

        @media #{$tabletOnly} {
          font-size: calc(14px + 1.2vw);
          line-height: 1.5;
        }
      }
    }

    .image-round {
      align-items: center;
      display: flex;
      justify-content: center;
      min-width: 200px;
      padding: 0px;

      @media #{$desktopAndLarger} {
        margin-right: 20px;
      }
    }
  }
}

// slide I

.brand-k-row-flex {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  justify-content: space-around;
  min-height: 60%;

  .row-flex {
    @media #{$mobileOnly} {
      flex-direction: column;
    }
  }

  @media #{$mobileOnly} {
    flex-direction: column;
    min-height: 90%;
  }

  @media #{$tabletOnly} {
    flex-direction: column;
    min-height: 80%;
  }

  .featured-content-view {
    align-content: flex-start;
    justify-content: flex-start;
    min-width: 95%;
    width: 100%;
    @include vertFlex;

    .image {
      cursor: pointer;
      width: 100%;
    }

    .vert-text {
      align-items: flex-start;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      min-height: 150px;
      min-width: 100%;
      padding: 10px 20px;
      text-align: left;

      >div {
        width: 100%;
      }
    }

    .title {
      color: #000000;
      font-size: 22px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
    }

    .name {
      color: #000000;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
    }

    .stats {
      @include spaceFlex;
      color: #000000;
      justify-content: flex-start;

      div:nth-of-type(1) {
        margin-right: 30px;
      }
    }
  }
}

.brand-lightbox {
  align-items: center;
  border: none;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  outline-color: transparent;
  outline-width: 0px;
  text-shadow: 0 0 0 #000;
  width: 100%;

  .brand-iframe {
    border: none;
    height: 540px;
    width: 960px;

    @media #{$mobileOnly} {
      height: 202px;
      width: 360px;
    }

    @media #{$tabletOnly} {
      height: 319px;
      width: 560px;
    }
  }

  .brand-iframe-container {
    align-items: center;
    border: none;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .exit {
      align-items: center;
      border-radius: 20px;
      bottom: 570px;
      color: white;
      cursor: pointer;
      display: flex;
      font-size: 25px;
      font-weight: 500;
      height: 40px;
      justify-content: center;
      left: 945px;
      position: relative;
      right: 0px;
      width: 40px;

      @media #{$mobileOnly} {
        bottom: 290px;
        left: 160px;
      }

      @media #{$tabletOnly} {
        bottom: 345px;
        left: 545px;
      }
    }
  }
}

:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.slide-k-container {
  @include spaceFlex;
  align-items: center;
  background-image: linear-gradient(to bottom, #3a74e0, #8052f5 100%);
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 135px;
  width: 100%;
  z-index: 5;

  @media #{$mobileOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
    justify-content: flex-start;
    padding-top: 130px;
  }

  @media #{$tabletOnly} {
    clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
    justify-content: flex-start;
    padding-top: 130px;
  }

  @media #{$desktopAndLarger} {
    clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
  }

  .brand-logo-grid {
    align-items: flex-start;
    display: grid;
    grid: 150px / auto auto auto auto;
    grid-auto-rows: auto;
    grid-gap: 10%;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;

    @media #{$mobileOnly} {
      grid: 100px / auto auto;

      >div {
        max-width: 100px;
      }
    }

    @media #{$tabletOnly} {
      grid: 150px / auto auto auto;

      >div {
        max-width: 150px;
      }
    }

    @media #{$desktopAndLarger} {
      >div {
        max-width: 100px;
      }
    }
  }
}

.cascade-fade-c {
  opacity: 0;
}

.cascade-fade-d {
  opacity: 0;
}

.cascade-fade-e {
  opacity: 0;
}

.cascade-fade-f {
  opacity: 0;
}

.cascade-fade-g {
  opacity: 0;
}

.cascade-fade-i {
  opacity: 0;
}

.brand-k-title {
  @include medLargeHeadline;
  font-size: 48px;
  margin-bottom: $titleMargin;

  @media #{$mobileOnly} {
    align-items: center;
    display: flex;
    font-size: 38px;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
  }

  @media #{$tabletOnly} {
    align-items: center;
    display: flex;
    font-size: 38px;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
  }

  @media #{$desktopAndLarger} {
    align-items: center;
    display: flex;
    font-size: 48px;
    justify-content: center;
    text-align: center;
  }
}

// HEAVYLIFT

.big-line-down {
  left: 49.4%;
  position: absolute;
  top: 250px;
  z-index: 4;

  @media #{$mobileOnly} {
    left: 4.43%;
    top: 295px;
  }

  @media #{$tabletOnly} {
    left: 4.43%;
    top: 290px;
  }
}

.slide-j-container {
  @include vertFlex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  width: 100%;
  z-index: 5;

  .brand-j-title {
    @include medLargeHeadline;

    @media #{$mobileOnly} {
      align-items: center;
      display: flex;
      font-size: 38px;
      justify-content: center;
      text-align: center;
    }

    @media #{$tabletOnly} {
      font-size: 38px;
    }

    @media #{$desktopAndLarger} {
      font-size: 48px;
    }
  }

  .brand-j-fixed-stack {
    .quick-reverse {
      flex-direction: row-reverse;
    }

    @media #{$mobileOnly} {
      @include trianglePokeyLeft;
    }

    @media #{$tabletOnly} {}

    @media #{$desktopAndLarger} {}
  }

  .brand-text-stack {

    background-color: #202227;
    max-width: 40vw;
    position: absolute;
    z-index: 2;

    @media #{$mobileOnly} {
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 85%;
      min-width: 255px;
    }

    @media #{$tabletOnly} {
      flex-direction: column;
      justify-content: flex-start;
      max-width: 80%;
      min-width: 80vw;
    }

    @media #{$desktopAndLarger} {
      width: calc(min(40vw, 770px));
    }

    .row-flex {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-height: 135px;
      min-height: 130px;
      padding: 15px;
      width: 100%;

      :nth-child(1) {
        flex-basis: 15%;
        min-width: 100px;
      }

      :nth-child(2) {
        flex-basis: 85%;
      }

      @media #{$mobileOnly} {
        align-items: center;
        flex-direction: row !important;
        justify-content: flex-start;
        min-height: 135px;

        :nth-child(1) {
          align-items: flex-start !important;
          flex-basis: 20%;
          justify-items: flex-start;
          margin-right: 5px;
          min-width: 90px;
        }

        :nth-child(2) {
          flex-basis: 80%;
        }
      }

      @media #{$tabletOnly} {
        min-height: 135px;
      }
    }

    .text-flex {
      @include vertFlex;
      align-items: flex-start;
      height: 100%;
      justify-content: flex-start;
      padding: 5px;

      .title {
        color: #ffffff;
        font-family: Roboto;
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 300;
        letter-spacing: normal;
        line-height: normal;
        margin-bottom: 10px;

        @media #{$mobileOnly} {
          font-size: 16px;
        }

        @media #{$tabletOnly} {
          font-size: 18px;
        }
      }

      .body {
        color: #83858c;
        font-family: Roboto;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.25;

        @media #{$mobileOnly} {
          font-size: 13px;
          line-height: double;
        }

        @media #{$tabletOnly} {
          font-size: 16px;
        }
      }
    }

    .image-round {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0px;

      @media #{$desktopAndLarger} {}
    }
  }
}

.contact-us-container {
  @include spaceFlex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 100px;
  width: 100vw;
  z-index: 5;

  @media #{$mobileOnly} {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: flex-end;

    .brand-title {
      margin-bottom: 20px;
    }

    >div {
      margin: 0;
      margin-bottom: 10px;
      max-width: 90%;
    }
  }

  @media #{$tabletOnly} {
    >div {
      max-width: 40%;
    }
  }

  @media #{$desktopAndLarger} {
    >div {
      max-width: 40%;
    }
  }
}

.brand-form-control {
  .contact-form-label {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
  }

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;

  @media #{$mobileOnly} {
    min-width: 80% !important;
  }

  .MuiSelect-root {
    border: none !important;
    border-radius: 0 !important;
  }

  .MuiSelect-icon {
    margin-right: 5px;
  }
}

// utils and one offs

.quick-reverse {
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start !important;
  margin-right: 10px;
}

.justify-end {
  justify-content: flex-end !important;
  margin-left: 10px;
}

.brand-rec-button {
  background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
  border-radius: 24px;
  color: white;
  cursor: pointer;
  font-family: Roboto;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  font-weight: bold;
  height: 48px;
  letter-spacing: normal;
  line-height: normal;
  width: 180px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: #3a74e0;
    color: #ffffff;
  }
}

.brand-image-container {
  padding: 0px 10px 0px 10px;
  width: 100%;

  img {
    width: 100%;
  }

  @media #{$mobileOnly} {
    img {
      height: 320px !important;
      width: 320px !important;
    }
  }

  @media #{$tabletOnly} {
    img {
      height: 270px;
      width: 270px;
    }
  }
}

.triangle-pokey-right {
  @include trianglePokeyRight;
}

.triangle-pokey-left {
  @include trianglePokeyLeft;
}

.fixed-contact-dealy {
  align-items: center;
  background-color: transparent;
  background-color: #18191d;
  border-bottom: 1px solid #5c5757;
  color: white;
  display: flex;
  font-family: Roboto;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 55px;
  justify-content: center;
  left: 0px;
  letter-spacing: normal;
  line-height: normal;
  opacity: 0;
  overflow-y: visible;
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 20;

  .inner-button {
    &:hover {
      background: #3a74e0;
      color: #ffffff;
    }
  }

  @media #{$mobileOnly} {
    font-size: 13px;
  }

  .inner-button {
    background-image: linear-gradient(to bottom, #3a74e0, #4a6fe6 31%, #5d69ea 67%, #8358ed);
    border-radius: 20px;
    color: white;
    cursor: pointer;
    font-weight: bold;
    height: 40px;
    margin-left: 20px;
    width: 140px;

    &:focus {
      outline: none;
    }

    &:hover {
      background: #3a74e0;
      color: #ffffff;
    }

    @media #{$mobileOnly} {
      border-radius: 15px;
      height: 30px;
      width: 100px;
    }
  }
}

.fade-up-prep {
  opacity: 0;
}