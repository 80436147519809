.AppReturn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.AppReturn_button {
	cursor: pointer;
	background: linear-gradient(to right, #48ced1, #40a2dc);
	outline: none;
	padding: 15px 30px;
	font-size: 14px;
	color: #FFF;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 30px;
	border: none;
	transition: transform 200ms ease-out;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}